import React, { useState,useContext, useEffect } from 'react'
import { Link,useLocation,useNavigate } from "react-router-dom";
import styles from './SignIn.module.css'
import {LoginInfluen} from "../../services/auth-service/InflAccountService";
import {LoginClient} from "../../services/auth-service/clientAccountService"
import { UserContext } from '../../App';
import AlertMessage from '../../components/shared/alertMessage/AlertMessage';

const SignIn =()=>{
  
  const {state,dispatch} = useContext(UserContext);
  const [alertData, setAlertData] = React.useState(null);


  const navigate = useNavigate();
  const location = useLocation();

  const [inputdata,setinputdata] = useState({
     email:'',
     password:'',
  })
  const [toggler,settoggler]=useState('influencer')
  const [isActiveButton1, setIsActiveButton1] = useState(false);
  const [isActiveButton2, setIsActiveButton2] = useState(true);

  useEffect(() => {
    if(location.state?.from){
      settoggler('client');
      setIsActiveButton1((prevIsActive) => !prevIsActive);
      setIsActiveButton2(false);  
    }
  }, [])

  const toggleClassButton1 = () => {
    settoggler('client')
    setIsActiveButton1((prevIsActive) => !prevIsActive);
    setIsActiveButton2(false); // Ensure the other button is inactive    
  };

  const toggleClassButton2 = () => {
    settoggler('influencer')
    setIsActiveButton2((prevIsActive) => !prevIsActive);
    setIsActiveButton1(false); // Ensure the other button is inactive
  };
  const handleinput =(e)=>{
    const name = e.target.name;
    const value = e.target.value;
    setinputdata({...inputdata,[name]:value})
  }
 const postData=async (e)=>{
    e.preventDefault();
   
    if(toggler==='influencer'){
    const  res = await LoginInfluen(inputdata)
      if(res === 400 || !res ){
        setAlertData({
          message: 'Invalid User!!',
        });
     }else{
      dispatch({type:'USER',payload:true})
      setinputdata([])
      navigate('/influencerhome')
     }
    }else if(toggler==='client'){
      const  res = await LoginClient(inputdata)
        if(res === 400 || !res ){
          setAlertData({
            message: 'Invalid User!!',
          });
       }else{
        dispatch({type:'USER',payload:true})
        setinputdata([])
        if(location.state?.from){
          navigate(location.state.from);
        }else{
        navigate('/clienthome')
        }
       }
    }
  }
const GoToForgotPassword = ()=>{
  navigate('/forgotpassword',{ state: { toggler: toggler } })
}

    return(
<>
<section className="inflanar-signin pd-top-120 pd-btm-120">
<div className="container">
<div className="row">
<div className="col-12">
<div className="inflanar-signin__body">
<div className="row">
<div className="col-lg-6 col-12">
<div className="inflanar-signin__logins">

<div className="inflanar-signin__header mg-btm-10">
<div className="inflanar-signin__heading">
<h4 className="inflanar-signin__title">Sign In</h4>
<p className="inflanar-signin__tag">Welcome to In Korero</p>
</div>
<div className="inflanar-signin__heading__options">

<div className="list-group inflanar-signin__options" id="list-tab" role="tablist">
<button className={`list-group-item ${isActiveButton2 ? 'active' : 'inactive'}`} 
style={{borderTopWidth:'1px'}} data-bs-toggle="list" name="client" value="client"  
role="tab" onClick={toggleClassButton2}>
<span>Influencer</span>
</button>
<button className={`list-group-item ${isActiveButton1 ? 'active' : 'inactive'}`} 
style={{borderTopWidth:'1px'}} data-bs-toggle="list" name="Influencer" value="Influencer"  
role="tab" onClick={toggleClassButton1}>
<span>User</span>
</button>
</div>
</div>
</div>

<div className="tab-content mg-top-30" id="nav-tabContent">
<div className="tab-pane fade active show" id="in-tab10" role="tabpanel">
<div className="inflanar-signin__inner">
<form  method='POST'>
<div className="row">
<div className="col-12">
<div className="form-group inflanar-form-input mg-top-20">
<label>Email*</label>
<input className="ecom-wc__form-input" type="email" name="email" 
placeholder="infoyour@gmail.com" required="required" value={inputdata.email}
onChange={handleinput} />
</div>
<div className="form-group inflanar-form-input mg-top-20">
<label>Password*</label>
<input className="inflanar-signin__form-input" 
placeholder="●●●●●●●●●●●●" id="password-field" type="password" 
name="password" required="required"  value={inputdata.password}
onChange={handleinput}
/>
</div>

<div className="form-group mg-top-40">
<button type="submit" className="inflanar-btn" onClick={postData}><span>Sign In</span></button>
</div>
<div className="form-group mg-top-20">
<div className="inflanar-signin__check-inline justify-content-center">
<div className="inflanar-signin__forgot">
<span onClick={GoToForgotPassword} className={`forgot-pass ${styles.forgot}`}>Forgot Password?</span>
</div>
</div>
</div>
<div className="inflanar-signin__bottom">

<p className="inflanar-signin__text">Don’t have an account?
<Link to='/auth/signup' className='ml-2'>  Create an account</Link></p>
</div>
</div>
</div>
</form> 
</div>
</div>

</div>
</div>
</div>
<div className="col-lg-6 col-12 d-none d-lg-block">
<div className="inflanar-signin__welcome inflanar-bg-cover inflanar-section-shape17">
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</section>
{alertData && (
  <div className="overlay">
    {/* You can add an overlay to dim the background if needed */}
    <AlertMessage
      message={alertData.message}
      type={alertData.type}
      onClose={() => setAlertData(null)}
    />
  </div>
)}
</>
    )
}

export default SignIn;